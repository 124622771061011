

























































































































































































import { BPagination, BSpinner } from "bootstrap-vue";
import { Component, Vue } from "vue-property-decorator";
import { Equipe } from "@/api/models/options/equipes/equipe";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { UserDetail } from "@/api/models/authorization/userDetails/userDetail";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { successAlert, errorAlert } from "@/libs/sweetAlerts/alerts";

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    BPagination,
    BSpinner,
  },
})
export default class DetailsEquipe extends Vue {
  modalLoading: boolean = false;

  selectedEquipe: Equipe | null = null;
  listUserDetails: PaginatedList<UserDetail> = new PaginatedList<UserDetail>();

  currentPage = 1;
  pageSize = 20;

  ssieInput: string = "";
  nomInput: string = "";
  prenomInput: string = "";
  identifiantInput: string = "";

  async created() {
    await this.loadEquipe([]);
    await this.loadUserDetailsList();
  }

  isMembre(userDetailId: string) {
    return this.selectedEquipe?.membres.some((m) => m.id == userDetailId);
  }

  async loadEquipe(params: any) {
    await this.$http.myressif.equipes
      .getById(this.$route.params.equipeId)
      .then((response: Equipe) => {
        this.selectedEquipe = response;
      });
  }

  async loadUserDetailsList() {
    this.modalLoading = true;

    await this.$http.myressif.userDetails
      .paginatedList(
        this.currentPage,
        this.pageSize,
        this.nomInput,
        this.prenomInput,
        this.ssieInput,
        this.identifiantInput
      )
      .then(
        (response: PaginatedList<UserDetail>) => {
          this.listUserDetails = response;
          this.modalLoading = false;
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des utilisateurs",
          });
        }
      );
  }

  removeMembre(membreId: string) {
    this.$bvModal
      .msgBoxConfirm(
        "Etes-vous sûr de vouloir retirer ce membre de l'équipe ?",
        {
          title: "Confirmation de modification",
          size: "sm",
          okVariant: "primary",
          okTitle: "Oui",
          cancelTitle: "Non",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      )
      .then(async (value) => {
        if (value === true) {
          await this.confirmRemove(membreId);
        } else {
          return;
        }
      });
  }

  async confirmRemove(membreId: string) {
    await this.$http.myressif.equipes
      .removeMembre(this.$route.params.equipeId, membreId)
      .then(
        async (response: string) => {
          successAlert.fire({
            title: "Retrait d'un membre de l'équipe",
            text: "Modification effectuée avec succès",
          });

          await this.loadEquipe({});
        },
        (error: any) => {
          errorAlert.fire({
            text: error.message,
          });
        }
      );
  }

  async addMembre(membreId: string) {
    await this.$http.myressif.equipes
      .addMembre(this.$route.params.equipeId, membreId)
      .then(
        (response: any) => {
          this.loadEquipe({});
          this.nomInput = "";
          this.prenomInput = "";
          this.ssieInput = "";
          this.identifiantInput = "";
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors de l'ajout du membre à l'équipe",
          });
        }
      );
  }
}
